import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
/*   {
    path: 'mainbar',
    loadChildren: () => import('./modules/account/account.module').then((module) => module.AccountModule),
  }, */
  {
    path: "**",
    //component: EmptyRouteComponent
    loadChildren: () => import('./modules/account/account.module').then((module) => module.AccountModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
